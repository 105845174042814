import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import * as s from "./styles/globalStyles";
import { Link, Element } from 'react-scroll';
import useSound from 'use-sound';
import { Splide, SplideSlide } from "@splidejs/react-splide";
import '@splidejs/splide/dist/css/splide.min.css';

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [tokensOfOwner, setTokensOfOwner] = useState([]);

  const checkTokensOfOwner = async () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      const tokenIds = await blockchain.smartContract.methods.walletOfOwner(blockchain.account).call();
      setTokensOfOwner(tokenIds);
    }
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [currentPopupData, setCurrentPopupData] = useState({});

  const openPopup = (data) => {
    setIsPopupOpen(true);
    setCurrentPopupData(data);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setCurrentPopupData({});
  };

  const [playing, setPlaying] = useState(false);
  const [play, { stop }] = useSound("https://arweave.net/5syst3c7iFAXG0WG-irJGfkEhNciumVKIMJIoqeTe_0/0.mp3");
  const togglePlay = () => {
    if (playing) {
      stop();
    } else {
      play();
    }
    setPlaying(!playing);
  };



  const portfolioData = [
    {
      imageSrc: '/config/images/character_dread.jpg',
      imageSrcP: '/config/images/character_dread_p.jpg',
      title: 'Dread'
    },
    {
      imageSrc: '/config/images/character_afro.jpg',
      imageSrcP: '/config/images/character_afro_p.jpg',
      title: 'Afro'
    },
    {
      imageSrc: '/config/images/character_hapi.jpg',
      imageSrcP: '/config/images/character_hapi_p.jpg',
      title: 'Hapi'
    },
    {
      imageSrc: '/config/images/character_oken.jpg',
      imageSrcP: '/config/images/character_oken_p.jpg',
      title: 'Oken'
    },
    {
      imageSrc: '/config/images/character_dandy.jpg',
      imageSrcP: '/config/images/character_dandy.jpg',
      title: 'Dandy'
    },
    {
      imageSrc: '/config/images/character_bear.jpg',
      imageSrcP: '/config/images/character_bear.jpg',
      title: 'Bear'
    },
    {
      imageSrc: '/config/images/character_dandy.jpg',
      imageSrcP: '/config/images/character_dandy.jpg',
      title: 'Green'
    }
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const modalRef = useRef(null);

  const handleLinkClick = () => {
    setModalOpen(true);
  };

  const handleOptionClick = (selectedOption) => {
    // 選択したオプションに基づいて遷移処理を行う
    if (selectedOption === "optionA") {
      window.open(
        "https://oken.cloud.vket.com/worlds/C5HaqfzS/main.html?worldid=C5HaqfzS",
        "_blank"
      );
    } else if (selectedOption === "optionB") {
      // もう一つの遷移先のURLを指定する
      window.open("https://www.spatial.io/s/EXODUS_otto-6663e669bf3c145faf958b08?share=8299738165735526892", "_blank");
    }

    // モーダルを閉じる
    setModalOpen(false);
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setModalOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    checkTokensOfOwner();
  }, []);

  useEffect(() => {
    checkTokensOfOwner();
  }, [blockchain.account]);

  return (
    <s.Screen>

      <s.NavBar>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <s.Logo>
            <img style={{ maxWidth: "75px" }} src="/config/images/logo.png" alt="EXODUS LOGO" />
          </s.Logo>
          <s.NavMenu>
            {/*
            <Link to="Story" smooth={true} duration={600} style={{ marginRight: "10px", textDecoration: "none" }}>
              Story
            </Link>
            */}
            <Link to="Record" smooth={true} duration={600} style={{ fontFamily:"Baskerville",marginRight: "10px", textDecoration: "none" }}>
              Record
            </Link>
            <Link to="Gallery" smooth={true} duration={600} style={{ fontFamily:"Baskerville",marginRight: "10px", textDecoration: "none" }}>
              Gallery
            </Link>
            <Link to="Project" smooth={true} duration={600} style={{ fontFamily:"Baskerville",marginRight: "16px", textDecoration: "none" }}>
              Project
            </Link>
          </s.NavMenu>
        </div>
      </s.NavBar>

      <div style={{ color: "#fff", width: "100%", maxWidth: "1280px", margin: "0 auto" }}>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat", maxHeight: "780px", height: "80vh", backgroundImage: "linear-gradient(90deg,rgba(0,0,0,0.3) 4%,rgba(0,0,0,0.1) 8%, rgba(0,0,0,0.2) 30%, rgba(0,0,0,0.5) 45%,rgba(0,0,0,1) 96%),url(/config/images/bg4.jpg)", backgroundPosition: "left bottom" }}>
          <div style={{ maxWidth: "340px", paddingBottom: "56px" }}>
            <div style={{ textAlign: "center" }}>
              <p style={{ fontSize: "32px" }}>About</p>
              <p style={{ marginTop: "4px", fontSize: "12px" }}>- EXODUSについて -</p>
            </div>
            <div style={{ fontSize: "14px", textAlign: "center", lineHeight: "1.5em" }}>
              <p style={{ marginTop: "16px" }}>EXODUS' story and worldview through</p>
              <p>「Music」「Art」「Metaverse」</p>
              <p>This is a Web3 creative project</p>
            </div>
            <div style={{ fontSize: "14px", textAlign: "center", lineHeight: "1.5em" }}>
              <p style={{ marginTop: "8px" }}>EXODUSのストーリーや世界観を</p>
              <p>「音楽」「アート」「メタバース」で表現する</p>
              <p>Web3クリエイティブプロジェクト</p>
            </div>
            <div style={{ textAlign: "center", marginTop: "24px" }}>
              <a href="https://twitter.com/EXODUS_FFW" target="_blank" rel="noopener" style={{ textDecoration: "none" }}><img style={{ padding: "3px", maxWidth: "28px" }} src="/config/images/logo-x.png" alt="X logo" /></a>
              <a href="https://discord.gg/E2AT2db4uv" target="_blank" rel="noopener" style={{ textDecoration: "none" }}><img style={{ margin: "0px 8px", padding: "5px", maxWidth: "34px" }} src="/config/images/logo-discord.png" alt="Discord logo" /></a>
              <a href="https://opensea.io/collection/exodus-1st" target="_blank" rel="noopener" style={{ textDecoration: "none" }}><img style={{ padding: "1px", maxWidth: "29px" }} src="/config/images/logo-os.png" alt="OpenSea logo" /></a>
            </div>
          </div>
        </div>
      </div>

      <div style={{ backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundImage: "linear-gradient(90deg,rgba(0,0,0,1) 20%,rgba(0,0,0,0.4) 50%,rgba(0,0,0,1) 90%),url(/config/images/bg.jpg)", backgroundPosition: "top", width: "100%", maxWidth: "1280px", margin: "0 auto", paddingBottom: "16px" }}>
        <Element name="Story" className="Story"></Element>
        <div style={{ margin: "32px 0px 32px", color: "#fff" }}>
          <div style={{ textAlign: "left", margin: "24px" }}>
            <p style={{ fontSize: "32px", textDecoration: "underline" }}>Story</p>
          </div>
          <div style={{ textAlign: "left", padding: "0px 32px 0px", lineHeight: "2.2em", fontSize: "14px" }}>
            <p style={{ marginTop: "24px" }}>2045年</p>
            <p>世界で一番 煌びやかで凶悪な街 BONUTY CITY</p>
            <p>Mafia達の目を掻い潜り 密かに暗躍している</p>
            <p>親や身寄りの無い Street children上がりの集団</p>
            <p style={{ margin: "12px 0px", fontSize: "20px", fontWeight: "bold" }}>"EXODUS"</p>
            <p>ストリートでブラックマネーを稼いで</p>
            <p style={{ marginBottom: "12px" }}>仲間たちの生活を守る為に命を削っている</p>
            <p>EXODUSのメンバーには それぞれ夢があった</p>
            <p>生きる為に必死な彼等だが 夢は諦めていない</p>
            <p>それは音楽やアートで勝ち上がり</p>
            <p>このゴミ臭い街から這い上がって</p>
            <p>安心してベットで眠れる生活を手にすること</p>
            <p>そのために日々戦っている ーー</p>
          </div>
        </div>
        <div style={{ marginLeft: "32px" }}>
          <a href="https://stand.fm/episodes/650bfe40c313b3502cf8a789" target="_blank" rel="noopener" style={{ textDecoration: "none" }}><s.StyledButtonStandFM>stand.fm</s.StyledButtonStandFM></a>
        </div>
        <div style={{ textAlign: "right", padding: "12px 32px 0px", fontSize: "14px", color: "#fff" }}>
          <p style={{ marginBottom: "12px" }}>Side story → <a href="https://exodus-ffw-sidestory.diary.to/" target="_blank" rel="noopener" style={{ color: "#fff" }}>"情報屋Uのメモ"</a></p>
        </div>




        <div style={{ backgroundImage: "linear-gradient(90deg,rgba(0,0,0,0),rgba(50,50,50,0))", backgroundPosition: "center", width: "100%", maxWidth: "1280px", margin: "0 auto", paddingBottom: "24px" }}>
          <Element name="Character" className="Character"></Element>
          <div style={{ margin: "32px 0px 32px" }}>
            <div style={{ maxWidth: "880px", width: "100%", margin: "0 auto" }}>
              <div style={{ margin: "24px", textAlign: "left" }}>
                <s.CharacterTape>- Character -</s.CharacterTape>
              </div>
            </div>

            <div style={{ width: '100%', overflowX: 'auto', padding: '0px 6px', margin: "0 auto" }}>
              <s.ResponsiveWrapper style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                {portfolioData.map((item, index) => (
                  <s.PopupButton key={index} onClick={() => openPopup(item)}>
                    <s.PortfolioCard>
                      <div style={{ position: 'relative' }}>
                        <s.CharacterButtonImg src={item.imageSrc} alt={item.title} />
                        <s.CharacterButton>
                          {item.title}
                        </s.CharacterButton>
                      </div>
                    </s.PortfolioCard>
                  </s.PopupButton>
                ))}

                {isPopupOpen && (
                  <s.PopupOverlay onClick={closePopup} style={{ zIndex: 1000 }}>
                    <s.PopupContent onClick={(e) => e.stopPropagation()}>
                      <div style={{ paddingTop: "16px", display: "flex", justifyContent: "center", alignItems: "center", maxWidth: "95%" }}>
                        <img style={{ maxWidth: "100%" }} src={currentPopupData.imageSrcP} alt={currentPopupData.title} />
                      </div>
                      <s.TextDescription style={{ margin: "6px 20px 12px" }}>{currentPopupData.description}</s.TextDescription>

                      <s.TextDescription><a href={currentPopupData.link1} target="_blank" rel="noopener" style={{ color: "#4D4D4D", fontWeight: "bold" }}>{currentPopupData.linkTitle1}</a></s.TextDescription>
                      <s.TextDescription>{currentPopupData.linkComment1}</s.TextDescription>
                      <s.TextDescription style={{ marginBottom: "8px" }}>{currentPopupData.specification1}</s.TextDescription>

                      <s.PopupCloseButton onClick={closePopup}>×</s.PopupCloseButton>
                    </s.PopupContent>
                  </s.PopupOverlay>
                )}
              </s.ResponsiveWrapper>
            </div>

          </div>
        </div>
      </div>

      <div style={{ width: "100%", maxWidth: "1280px", margin: "0 auto" }}></div>
      <Element name="Gallery" className="Gallery"></Element>
      <div style={{ margin: "0px 0px 32px", color: "#fff" }}>
        <div style={{ textAlign: "center", margin: "24px,0px,4px" }}>
          <div style={{ fontFamily:"Baskerville",fontSize: "28px", padding: "16px", marginTop: "18px" }}>Gallery</div>
        </div>

        <div style={{ textAlign: "center", maxWidth: "980px", margin: "0 auto" }}>

          <Splide
            style={{ textAlign: "center", width: "100%", margin: "0 auto" }}
            aria-label="Galaly"
            options={{
              rewind: true,
              autoplay: true, // 自動再生を有効
              interval: 5000, // 自動再生の間隔を3秒に設定
            }}
          >
            <SplideSlide style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img style={{ width: "100%" }} className="slide-img" src="/config/images/gallery1.png" alt="part1" />
            </SplideSlide>
            <SplideSlide style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img style={{ width: "100%" }} className="slide-img" src="/config/images/gallery2.png" alt="part2" />
            </SplideSlide>
            <SplideSlide style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img style={{ width: "100%" }} className="slide-img" src="/config/images/gallery3.png" alt="part3" />
            </SplideSlide>
            <SplideSlide style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img style={{ width: "100%" }} className="slide-img" src="/config/images/gallery4.png" alt="part4" />
            </SplideSlide>
            <SplideSlide style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img style={{ width: "75%" }} className="slide-img" src="/config/images/gallery5.png" alt="part1" />
            </SplideSlide>
          </Splide>
        </div>

        {/* 
        <div style={{ padding: "0px 32px 24px" }}>
          <div style={{ textAlign: "center" }}>
            <s.TextSubTitle
              style={{ margin: "24px 12px 2px", textAlign: "center", fontSize: "12px" }}
            >
              EXODUS (inst)
            </s.TextSubTitle>
            <s.StyledButtonStandFM onClick={togglePlay}>
              {playing ? 'Stop' : 'Play'}
            </s.StyledButtonStandFM>
          </div>
        </div>
        **/}
      </div>


      <div style={{ backgroundImage: "linear-gradient(90deg,rgba(0,0,0,0.6),rgba(0,0,0,0.1),rgba(0,0,0,0.6)),url(/config/images/woodchip.jpeg)", backgroundPosition: "center", width: "90%", maxWidth: "1280px", margin: "0 auto", width: "100%"}}>
        <Element name="Record" className="Record"></Element>
        <div style={{ fontFamily:"Baskerville",margin: "0 auto", textAlign: "center", color: "#fff", fontSize: "28px", margin: "32px 0px 0px" }}>Records</div>

        <div style={{ width: "100%", maxWidth: "800px", margin: "0 auto", padding: "16px 32px" }}>

          <div style={{ display: 'flex', justifyContent: 'center'}}>
            <a href="https://opensea.io/assets/ethereum/0x9a4483688f69507100445cd5e14acdf90d9ba340/1" target="_blank" rel="noopener noreferrer" style={{ display: "block", width: "27%", height: "27%" }}>
              <s.RecordImg src="/config/images/00.jpg" alt="Image 1" />
            </a>
            <a href="https://opensea.io/assets/matic/0x7f6d31402e5606e7b0b80d2a40dc41c01308a766/0" target="_blank" rel="noopener noreferrer" style={{ display: "block", margin: "0px 20px", width: "27%", height: "27%" }}>
              <s.RecordImg src="/config/images/0.jpg" alt="Image 2" />
            </a>
            <a href="https://opensea.io/assets/matic/0x7f6d31402e5606e7b0b80d2a40dc41c01308a766/1" target="_blank" rel="noopener noreferrer" style={{display: "block", width: "27%", height: "27%" }}>
              <s.RecordImg src="/config/images/1.jpg" alt="Image 3" />
            </a>
          </div>
          <s.WoodImg src="/config/images/wood1.jpeg" />

          

          <div style={{ position: "relative", display: 'flex', justifyContent: 'center', flexWrap: 'wrap', flexDirection: "row", zIndex: "1", marginTop: "36px" }}>
            <a href="https://opensea.io/assets/matic/0x7f6d31402e5606e7b0b80d2a40dc41c01308a766/2" target="_blank" rel="noopener noreferrer" style={{ display: "block", width: "27%", height: "27%" }}>
              <s.RecordImg src="/config/images/2.jpg" alt="Image 4" />
            </a>
            <a href="https://x.com/kush81nft/status/1779283996591247414" target="_blank" rel="noopener noreferrer" style={{ display: "block", margin: "0px 0px 0px 20px", width: "27%", height: "27%" }}>
              <s.RecordImg src="/config/images/3.jpeg" alt="Image 5" />
            </a>
          </div>
          <s.WoodImg src="/config/images/wood2.jpeg" />
        </div>
      </div>




      <div style={{ backgroundImage: "linear-gradient(90deg,rgba(0,0,0,1),rgba(0,0,0,0.4),rgba(0,0,0,1)),url(/config/images/bg2.jpg)", backgroundPosition: "center", width: "100%", maxWidth: "1280px", margin: "0 auto" }}>
        <Element name="Project" className="Project"></Element>
        <div style={{ margin: "32px 0px 32px", color: "#fff" }}>
          <div style={{ textAlign: "center", margin: "8px" }}>
            <p style={{ fontFamily:"Baskerville",fontSize: "28px" }}>Project</p>
          </div>

          <div style={{ padding: "0px 32px 0px" }}>
            <div
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >

              <s.ProjectArea>
                <a href="https://opensea.io/collection/exodus-record" target="_blank" rel="noopener" style={{ textDecoration: "none" }}>
                  <img
                    style={{ width: "80%", maxWidth: "320px", margin: "0 auto" }}
                    src="/config/images/pj1.jpg"
                    alt="EXODUS RECORD"
                  />
                </a>
              </s.ProjectArea>

              <s.ProjectArea>
                <a href="https://opensea.io/collection/exodus-1st" target="_blank" rel="noopener" style={{ textDecoration: "none" }}>
                  <img
                    style={{ width: "80%", maxWidth: "320px", margin: "0 auto" }}
                    src="/config/images/pj2.jpg"
                    alt="EXODUS Art Generative"
                  />
                </a>
              </s.ProjectArea>
            </div>

            <div style={{ textAlign: "center", display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>

              <s.ProjectArea>
                <div onClick={handleLinkClick} style={{ position: "relative", textDecoration: "none", cursor: "pointer" }}>
                  <img
                    style={{ width: "80%", maxWidth: "320px", margin: "0 auto" }}
                    src="/config/images/pj3.jpg"
                    alt="EXODUS Metaverse"
                  />
                  {modalOpen && (
                    <div
                      ref={modalRef}
                      style={{
                        position: "absolute",
                        top: 0,
                        left: "10%",
                        width: "80%",
                        height: "99%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(0, 0, 0, 0.3)",
                        zIndex: 1,
                      }}
                    >
                      <div style={{ textAlign: "center", color: "#fff" }}>
                        <button
                          onClick={() => handleOptionClick("optionA")}
                          style={{ width: "160px", cursor: "pointer", margin: "10px", padding: "10px 20px", backgroundColor: "#000", color: "#fff", border: "none" }}
                        >
                          CLUB SICKth
                        </button>
                        <button
                          onClick={() => handleOptionClick("optionB")}
                          style={{ width: "160px", cursor: "pointer", margin: "10px", padding: "10px 20px", backgroundColor: "#000", color: "#fff", border: "none" }}
                        >
                          Kids room
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </s.ProjectArea>

              <s.ProjectArea>
                <a href="https://www.unknownartworkzshop.com/" target="_blank" rel="noopener" style={{ textDecoration: "none" }}>
                  <img
                    style={{ width: "80%", maxWidth: "320px", margin: "0 auto" }}
                    src="/config/images/pj4.jpg"
                    alt="EXODUS Apparel shop"
                  />
                </a>
              </s.ProjectArea>
            </div>
          </div>
        </div>
      </div>

      <s.TextSubTitle
        style={{ margin: "42px 24px 0px", textAlign: "left" }}
      >
        - Menber -
      </s.TextSubTitle>
      <s.TextSubTitle
        style={{ margin: "2px 24px 4px", textAlign: "left" }}
      >
        <a href="https://twitter.com/kush81nft" target="_blank" rel="noopener" style={{ color: "rgba(255,255,255,1)", textDecoration: "underline" }}>kush81</a> (Deejay)<br />
        <a href="https://twitter.com/SeedBrothers_SB" target="_blank" rel="noopener" style={{ color: "rgba(255,255,255,1)", textDecoration: "underline" }}>K-J</a> (Deejay)<br />
        <a href="https://twitter.com/404SHiNO403" target="_blank" rel="noopener" style={{ color: "rgba(255,255,255,1)", textDecoration: "underline" }}>SHiNO</a> (Illustrator)<br />
        <a href="https://twitter.com/dandylion_nft" target="_blank" rel="noopener" style={{ color: "rgba(255,255,255,1)", textDecoration: "underline" }}>Dandy Lion</a> (Beat maker)<br />
        <a href="https://twitter.com/katakurakenchan" target="_blank" rel="noopener" style={{ color: "rgba(255,255,255,1)", textDecoration: "underline" }}>OKEN</a> (Metaverse creator)<br />
        <a href="https://twitter.com/Chiya0909" target="_blank" rel="noopener" style={{ color: "rgba(255,255,255,1)", textDecoration: "underline" }}>hapi_pupepo</a> (Facilitator)<br />
        <a href="https://twitter.com/lettuce908" target="_blank" rel="noopener" style={{ color: "rgba(255,255,255,1)", textDecoration: "underline" }}>Lettuce</a> (Engineer)<br />
      </s.TextSubTitle>

      <s.TextSubTitle
        style={{ margin: "42px 12px 4px", textAlign: "center" }}
      >
        Copyright © 2023 - EXODUS
      </s.TextSubTitle>
      <s.SpacerSmall />

    </s.Screen>
  );
}
export default App;