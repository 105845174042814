import styled from "styled-components";

export const Screen = styled.div`
  font-family: Cambria,sans-serif;
  background-color: #000;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 300px) {
    flex-direction: row;
  }
`;

export const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
`;

export const NavBar = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  background: rgba(0,0,0,1);
`;

export const Logo = styled.div`
  padding-top: 6px;
  padding-left: 12px;

`;

export const NavMenu = styled.div`
  color: rgba(255,255,255,0.9);
  padding-top: 14px;
  height: 40px;
  font-size: 14px;
  display: flex;
  alignItems: center;
`;

export const StyledButton = styled.button`
  font-family: Nunito,sans-serif;
  border: solid 1px rgba(20, 20, 20, 0.8);
  padding: 10px;
  border-radius: 30px;
  background-color: rgba(50, 50, 50, 0);
  font-weight: 500;
  font-size: 10px;
  color: #333;
  width: 180px;
  cursor: pointer;
  box-shadow: 0px 2px 0px 0px rgba(20, 20, 20, 0.6);
  -webkit-box-shadow: 0px 2px 0px 0px rgba(20, 20, 20, 0.6);
  -moz-box-shadow: 0px 2px 0px 0px rgba(20, 20, 20, 0.6);
  transition: .1s;
  :hover {
    transform: translate3d(0, 3px, 0);
    box-shadow: none;
  }
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    }
`;

export const StyledButtonStandFM = styled.button`
  border: solid 1px rgba(255, 255, 255, 0.8);
  padding: 10px;
  background-color: rgba(255, 255, 255, 0);
  font-weight: 500;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  width: 128px;
  cursor: pointer;
  transition: .5s;
  :hover {
    color: rgba(0, 0, 0, 1);
    background-color: rgba(255, 255, 255, 1);
    box-shadow: none;
  }
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    }
`;


export const PortfolioCard = styled.p`
  padding: 0px 5px 8px;
`;

export const PortfolioTitle = styled.p`
  color: #4d4d4d;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 1.5px;
  letter-spacing: 0.36px;
  opacity: 1;
  text-align: center;
  width: 100%;
  margin-bottom: 2px;
`;


export const TextTitle = styled.p`
  text-align: center;
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: 0.48px;
  opacity: 1;
`;

export const TextMiniTitle = styled.p`
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0.46px;
  opacity: 1;
`;

export const TextSubTitle = styled.p`
  text-align: center;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0.46px;
  opacity: 1;
`;

export const TextDescription = styled.p`
  color: #fff;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 1.5px;
  letter-spacing: 0.36px;
  opacity: 1;
  text-align: center;
  font-weight: 500;
`;

export const PopupButton = styled.div`
  margin: 0px 4px;
  cursor: pointer;
}
`;
export const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  animation: animation-keyframes 0.2s ease-in-out;
  @keyframes animation-keyframes {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
`;

export const PopupContent = styled.div`
  width: 80%;
  height: 70%;
  max-width: 540px;
  overflow-y: auto;
  background-color: white;
  padding: 36px 4px 16px 24px;
  text-align: center;
  position: relative;
  border-radius: 2px;
  @media (max-width: 400px) {
    max-height: 320px;
  }
}
`;

export const PopupCloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0px 16px;
  background-color: rgba(0, 0, 0, 0);
  font-size: 42px;
  color: #7d7d7d;
  border: none;
  cursor: pointer;
  font-family: Nunito,sans-serif;
  font-weight: 200;
}
`;

export const CharacterButton = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255,255,255,1);
}
`;

export const CharacterButtonImg = styled.img`
  max-width: 86px;
  border-radius: 50%;
  filter: brightness(0.9);
  }
}
`;

export const CharacterTape = styled.p`
  padding: 8px 4px;
  fontSize:24px;
  color:#fff;
  transform: rotate(-4deg);
}
`;

export const RecordImg = styled.img`
  max-width: 100%;
  filter: brightness(0.9);
  transition: .1s;
  :hover {
    filter: brightness(1);
  }
}
`;

export const WoodImg = styled.img`
display: block;
  width: 100%;
  margin-top:-2px;
}
`;

export const ProjectArea = styled.div`
  filter: brightness(0.9);
  transition: .1s;
  padding: 12px;
  :hover {
    filter: brightness(1);
  }
}
`;

export const SpacerXSmall = styled.div`
  height: 8px;
  width: 8px;
`;
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
`;
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`;